import { useContext, MouseEvent } from 'react';
import Image from '@next/image';
import classNames from 'classnames';

import Price from 'types/Price';
import ImageFile from 'types/ImageFile';
import Disallowed from 'types/Disallowed';
import SVGComponent from 'types/SVGComponent';
import { SearchItemAttribute } from 'types/models/SearchItemAttribute';
import PlusCartIcon from 'assets/icons/plus-cart.svg';
import { SkeletonImageContext } from '@providers/SkeletonImageProvider';
import PossibleLink, { Props as PossibleLinkProps } from '@components/atoms/PossibleLink';
import ComplexDeliveryLabel, { Props as ComplexDeliveryLabelProps } from '@components/ComplexDeliveryLabel';
import Typography from '@components/atoms/Typography';
import Button from '@components/atoms/Button';
import HtmlPreview from '@components/HtmlPreview';
import StarRating from '@components/StarRating';
import Amount from '@templates/Amount';
import DeleteProduct from '@templates/DeleteProduct';
import createStyleVariables from 'utils/createStyleVariables';
import getPlainText from 'utils/getPlainText';
import { FormattedMessage, useIntl } from 'utils/intl';

import classes from './CompactProduct.module.scss';
import messages from './CompactProduct.messages';

type DeliveryProps = Omit<ComplexDeliveryLabelProps, 'className' | 'isAvailable'>;

type Props = {
  href?: PossibleLinkProps['href'];
  className?: string;
  quantity?: number;
  netPrice: Price;
  grossPrice: Price;
  productTypeId?: number;
  name: string;
  buttonTitle?: string;
  attributes?: SearchItemAttribute[];
  attributesNames?: string[];
  showAttributes?: boolean;
  isConfigurable?: boolean;
  thumbnail?: ImageFile;
  rating?: number;
  productCode?: string;
  producerPn?: string;
  isAvailable?: boolean;
  imagePreload?: boolean;
  imageLazyLoad?: boolean;
  addIcon?: SVGComponent;
  displayMode?: 'narrow' | 'default';
} & (
  | {
      onDeleteProduct: () => void;
      onAddToCart?: never;
    }
  | {
      onDeleteProduct?: never;
      onAddToCart: (e: MouseEvent<HTMLButtonElement>) => void;
    }
  | {
      onDeleteProduct?: never;
      onAddToCart?: never;
    }
) &
  (DeliveryProps | Disallowed<DeliveryProps>);

const CompactProduct = ({
  name,
  className,
  productTypeId,
  buttonTitle,
  quantity,
  netPrice,
  grossPrice,
  thumbnail,
  rating,
  producerPn,
  productCode,
  href,
  displayMode = 'default',
  addIcon: AddIcon = PlusCartIcon,
  isAvailable,
  isConfigurable,
  showAttributes,
  imagePreload,
  imageLazyLoad,
  currentSupplier,
  attributes = [],
  attributesNames = [],
  onDeleteProduct,
  onAddToCart,
  ...deliveryLabelProps
}: Props): JSX.Element => {
  const intl = useIntl();
  const { getSkeletonImage } = useContext(SkeletonImageContext);

  const skeletonIcon = getSkeletonImage('p_thumb_4', productTypeId);

  const mappedMainAttributes = attributes.reduce(
    (acc, attribute) => ({
      ...acc,
      [attribute.name]:
        typeof attribute.value === 'string' ? attribute.value : attribute.value?.values.join(', ') || '',
    }),
    {} as Record<string, string>
  );

  return (
    <div className={classNames(className, classes.wrapper, { [classes.desktop]: displayMode === 'default' })}>
      <div className={classes.imageWrapper}>
        <PossibleLink href={href}>
          {thumbnail && (
            <Image
              layout="fill"
              objectFit="contain"
              alt={`zdjęcie ${name}`}
              className={classes.image}
              resolveConfig={{
                preset: 'p_thumb_4',
                description: intl.formatMessage(messages.mainPhoto),
                productName: name,
                extension: thumbnail.extension,
                name: thumbnail.basename,
                width: thumbnail.width,
                height: thumbnail.width,
              }}
              skeletonBackground="white"
              skeletonIcon={skeletonIcon}
              skeletonIsExternal={!!skeletonIcon}
              hasPreload={imagePreload}
              hasLoading={!imagePreload && imageLazyLoad}
              hasLazyLoad={!imagePreload && imageLazyLoad}
              hasFade={!imagePreload && imageLazyLoad}
            />
          )}
        </PossibleLink>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <div className={showAttributes ? classes.nameContent : ''}>
            <PossibleLink className={classes.title} href={href} underline="hover">
              <Typography
                variant="h4"
                renderAs="div"
                lineHeight="20px"
                ellipsisLines={2}
                className={classes.name}
                title={getPlainText(name)}
              >
                <HtmlPreview htmlText={name} withWordbreaks />
              </Typography>
            </PossibleLink>
            {!showAttributes && (
              <div className={classes.ratingAndDeliveryWraper}>
                {typeof rating === 'number' && <StarRating rating={rating} starsCount={1} />}
                {typeof deliveryLabelProps.daysToDelivery !== 'undefined' && (
                  <ComplexDeliveryLabel
                    className={classNames(classes.deliveryLabel, {
                      [classes.longDeliveryLabel]: currentSupplier?.availabilityTimespan.type !== 'GREEN',
                    })}
                    isAvailable={isAvailable || false}
                    currentSupplier={currentSupplier}
                    {...deliveryLabelProps}
                  />
                )}
                {!!productCode && (
                  <Typography variant="caption1" title={productCode} lineHeight="14px">
                    {productCode}
                  </Typography>
                )}
                {!!producerPn && (
                  <Typography
                    variant="caption1"
                    className={classes.producerPn}
                    title={producerPn}
                    lineHeight="14px"
                    wordBreak="break-word"
                    withEllipsis
                  >
                    {producerPn}
                  </Typography>
                )}
              </div>
            )}
          </div>
          {!!quantity && (
            <div className={classNames(classes.quantity, classes.inMobile)}>
              <FormattedMessage values={{ quantity }} {...messages.quantityShortName} />
            </div>
          )}
        </div>

        {showAttributes && (
          <div className={classes.attributes}>
            {attributesNames.map((attributeName) => (
              <Typography
                variant="h4"
                key={attributeName}
                renderAs="div"
                className={classes.attribute}
                style={createStyleVariables({
                  attributeWidth: `${100 / attributesNames.length}%`,
                })}
              >
                <span className={classes.attributeLabel}>{`${attributeName}:`}</span>
                {mappedMainAttributes[attributeName]}
              </Typography>
            ))}
          </div>
        )}

        <div
          className={classNames(classes.content, classes.priceContent, { [classes.withAttributes]: showAttributes })}
        >
          <div className={classes.price}>
            <Amount type="netto" size="little" price={netPrice} className={classes.amount} isFaded />
            <Amount type="brutto" size="compact" price={grossPrice} className={classes.grossPrice} isBolded />
          </div>
          {!!quantity && (
            <div className={classNames(classes.quantity, classes.inDesktop)}>
              <FormattedMessage values={{ quantity }} {...messages.quantityShortName} />
            </div>
          )}
          {onDeleteProduct && <DeleteProduct onClick={onDeleteProduct} />}
          {onAddToCart && (
            <Button
              size="sm"
              color={isConfigurable ? 'tertiary' : 'primary'}
              name="add-to-cart"
              classes={showAttributes ? { root: classes.button, icon: classes.buttonIcon } : undefined}
              title={buttonTitle || intl.formatMessage(messages.addToCart)}
              disabled={!isAvailable && !isConfigurable}
              icon={<AddIcon />}
              onClick={(e) => {
                e.stopPropagation();

                if (!isAvailable) {
                  e.preventDefault();
                }

                onAddToCart(e);
              }}
              withoutIconFill={isConfigurable}
            >
              {showAttributes ? <div className={classes.buttonName}>{buttonTitle}</div> : undefined}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompactProduct;
